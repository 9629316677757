<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Категорийн жагсаалт
      </h3>
      <div class="action-section">
        <router-link to="/add-category">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-plus"
            :disabled="mArrayIncludes(role, ['client_care', 'other'])"
            >Нэмэх</el-button
          >
        </router-link>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх суртчилгааны нэр"
            />
          </div>
          <el-table
            :data="
              categoriesList.filter(
                data =>
                  !search ||
                  data.name_mon.toLowerCase().includes(search.toLowerCase())
              )
            "
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="img_url" label="Зураг" width="150px">
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.img_url"
                  style="width: 50px;"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name_mon" label="Монгол нэр">
            </el-table-column>
            <el-table-column prop="name_eng" label="Англи нэр">
            </el-table-column>
            <el-table-column prop="sort" label="Эрэмбэ"> </el-table-column>
            <el-table-column
              prop="service"
              label="Төрөл"
              width="150"
              :filters="
                servicesList.map(res => ({
                  text: res.name_mon,
                  value: res.name_eng
                }))
              "
              :filter-method="filterTag"
              filter-placement="bottom-end"
            >
              <template slot-scope="scope">
                <el-tag effect="plain" type="warning"
                  >{{ scope.row.service }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_active"
              label="Төлөв"
              width="200"
              :filters="[
                { text: 'Идэвхтэй', value: true },
                { text: 'Идэвхгүй', value: false }
              ]"
              :filter-method="filterStatusTag"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.is_active == true" type="success">
                  Идэвхтэй
                </el-tag>
                <el-tag v-if="scope.row.is_active == false" type="info">
                  Идэвхгүй
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              label="Үйлдэл"
              width="150px"
              v-if="!mArrayIncludes(role, ['client_care', 'other'])"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete-solid"
                  @click="deleteCat(scope.row.id)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <edit-category
      :categoryEditData="categoryEditData"
      :sendEdit="sendEdit"
      :closeProgress="closeProgress"
      :servicesList="this.servicesList"
    ></edit-category>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import EditCategory from "@/components/EditCategory";
import { getGroups } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
export default {
  created() {
    this.getServices();
  },
  mounted() {
    this.getCategoriesList();
    getGroups().then(data => {
      this.role = data;
    });
  },
  components: { EditCategory },
  data() {
    return {
      search: "",
      categoriesList: [],
      categoryEditData: {},
      sendEdit: false,
      sendDeleteItem: {
        id: 0
      },
      role: [],
      servicesList: []
    };
  },
  methods: {
    closeProgress() {
      this.categoryEditData = {};
      this.getCategoriesList();
      this.sendEdit = false;
    },
    showEdit(data) {
      this.categoryEditData = data;
      this.sendEdit = true;
    },
    filterTag(value, row) {
      return row.service === value;
    },
    filterStatusTag(value, row) {
      return row.is_active === value;
    },
    deleteCat(id) {
      this.sendDeleteItem.id = id;
      this.$confirm("Энэ категори устгахдаа итгэлтэй байна уу?", "Анхаар", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteCategory(this.sendDeleteItem).then(res => {
            if (res.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Категорийн мэдээлэл устгагдлаа",
                type: "success"
              });
              this.getCategoriesList();
            } else if (res.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Категорийн мэдээлэл устгахад алдаа гарлаа " +
                  " " +
                  res.error.message,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    },
    getCategoriesList() {
      service.getMainCategories().then(res => {
        this.categoriesList = res.data.data;
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    }
  }
};
</script>
