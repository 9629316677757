<template>
  <div>
    <el-dialog
      :title="'Үндсэн ангилал : ' + categoryEditData.name_mon + ' '"
      :visible.sync="sendEdit"
      @close="closeDialog()"
      width="40%"
      top="100px"
      class="process-dialog"
    >
      <div class="panel">
        <el-form
          :model="categoryEditData"
          :rules="rules"
          ref="categoryEditData"
          label-width="220px"
          class="demo-ruleForm"
        >
          <el-form-item label="Үндсэн ангилалын монгол нэр" prop="name_mon">
            <el-input
              style="width:250px"
              size="mini"
              v-model="categoryEditData.name_mon"
              placeholder="Үндсэн ангилалын нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Үндсэн ангилалын англи нэр" prop="name_eng">
            <el-input
              style="width:250px"
              size="mini"
              v-model="categoryEditData.name_eng"
              placeholder="Үндсэн ангилалын англи нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Өнгө" prop="color">
            <el-color-picker v-model="categoryEditData.color"></el-color-picker>
          </el-form-item>
          <el-form-item label="Үндсэн ангилалын зураг" prop="img_url">
            <el-input
              type="url"
              style="width:250px"
              size="mini"
              v-model="categoryEditData.img_url"
              placeholder="Үндсэн ангилалын зурагны url оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Статус" prop="is_active">
            <el-switch v-model="categoryEditData.is_active"></el-switch>
          </el-form-item>
          <el-form-item label="Эрэмбэ" prop="sort">
            <el-input-number
              :precision="2"
              :step="0.1"
              :min="1"
              size="mini"
              v-model="categoryEditData.sort"
            ></el-input-number>
          </el-form-item>
          <!-- <el-form-item label="Тоо" prop="count">
            <el-input-number
              :precision="2"
              :step="0.1"
              :min="1"
              size="mini"
              v-model="categoryEditData.count"
            ></el-input-number>
          </el-form-item> -->
          <el-form-item label="/сервес/" prop="service">
            <el-select
              v-model="categoryEditData.service"
              placeholder="Шилжих хуудас сонгоно уу"
              clearable
              size="mini"
            >
              <el-option
                v-for="(service, index) in servicesList"
                :label="service.name_mon"
                :value="service.name_eng"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Төрөл" prop="type">
            <el-select
              v-model="categoryEditData.type"
              placeholder="Харуулах төрөл сонгоно уу"
            >
              <el-option
                label="Харилцагч Бараа харуулах"
                value="all"
              ></el-option>
              <el-option label="Харилцагч харуулах" value="defR"></el-option>
              <el-option label="Бараа харуулах" value="defM"></el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" style="margin-right: 20;" @click="save"
            >Нэмэх</el-button
          >
          <router-link to="/banner" class="back-button">
            <el-button>
              Буцах
            </el-button>
          </router-link>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
export default {
  props: {
    categoryEditData: Object,
    sendEdit: Boolean,
    closeProgress: Function,
    servicesList: Array
  },
  methods: {
    save() {
      this.$refs.categoryEditData.validate(valid => {
        if (valid) {
          this.categoryEditData.count = 0;
          service.updateCategory(this.categoryEditData).then(response => {
            if (response.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Үндсэн ангилалын мэдээлэл засагдлаа",
                type: "success"
              });
              this.closeDialog();
            } else if (response.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Үндсэн ангилалын мэдээлэл засахад алдаа гарлаа " +
                  " " +
                  response.error.message,
                type: "warning"
              });
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    closeDialog() {
      this.closeProgress();
    }
  },
  data() {
    return {
      loadUpload: false,
      imageNull: "none",
      rules: {
        title: [
          {
            required: true,
            message: "Та зарлал нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ]
      }
    };
  }
};
</script>
<style></style>
